export const STORAGE_KEY = {
  EMAIL_OTP: "95155fe066a89ee0dff3668fa739a452",
  USER_AUTH: "0544112244b2ddddefb9fdeb60ad0532",
};
export const APP_SETTINGS = {
  DEVICE_TYPE: "CMS_WEB",
};

export const USER_STAUTS = {
  APPROVED: "approved",
  SUSPENDED: "suspend",
  REJECTED: "rejected",
  PENDING_REVIEW: "review",
  NETEVIA_SENT: "neteviaSent",
  UNBLOCK_USER: "unblock",
  BLOCK_USER: "block",
};

export const SAVE_PROFILE_KEYS = {
  firstName: "first_name",
  lastName: "surename",
  dateOfBirth: "dateOfBirth",
  email: "email",
  phoneNumber: "phone",
  ssn: "ssn",
  streetAddress: "address",
  city: "city",
  state: "state",
  postalCode: "postalCode",
};

export const ACCOUNT_VERIFICATION_METHODS = {
  EMAIL_OTP: "email",
  PHONE_OTP: "phone",
  AUTHENTICATOR: "mfa",
  TECH_SUPPORT: 4,
};

export const PAGINATION = {
  page: 0,
  perPage: 10,
};

export const TEAM_MEMBER_ROLE = {
  SUPERADMIN_ROLE: 1,
  ASSISTANT_ROLE: 2,
  USER_ROLE: 3,
};
export const PERMISSION_IDENTIFIER = {
  USERS: 101,
  NET_WORTH: 107,
  TICKET_MANAGMENT: 104,
  E_KYC_REQUEST_: 108,
  SUBSCRIBE_USER: 109,
};

export const INSURANCE = {
  WHOLE_LIFE: "Whole Life",
  LONG_TERM: "Long Term",
  UMBRELLA: "Umbrella",
  TERM_LIFE: "Term Life",
  INDIVIDUAL_DISABILITY: "Individual Disability",
  AUTO: "Auto",
};
export const ASSET = {
  INVESTMENT: "investment",
  INSURANCE: "insurance",
  REAL_ESTATE: "real_estate",
  DEPOSITORY: "depository",
  BANK: "Bank",
  INCOME: "income",
  OTHER1: "Other",
  ASSET: "asset",
  OTHER: "other",
  INVESTMENT_NAME: "Investment Name",
  ACCOUNT_NAME: "Account Name",
  INVESTMENT_TYPE: "Investment Type",
};

export const LIABILITIES = {
  LIABILITIES: "liability",
  CREDIT: "credit",
  CREDIT_CARD: "Credit Card",
  LOAN: "loan",
  CARD_NAME: "Card Name",
  LOAN_NAME: "Loan Name",
  CARD_TYPE: "Card Type",
  ACCOUNT_TYPE: "Account Type",
  LOAN_TYPE: "Loan Type",
};
export const ESTATE_STATUS = {
  REVIEW: "review",
  APPROVE: "approve",
  REJECT: "reject",
  SIGNATURE_PENDING: "signature_pending",
};

export const ESTATE_PLANNING_KEYS = {
  POWER_OF_ATTORNEY: "power_of_attorney",
  HIPAA_RELEASE_AND_AUTHORIZATION: "hippa_release_and_authorization",
  LIVING_WILL_HEALTH_CARE_PROXY: "living_will_health_care_proxy",
  LAST_WILL_AND_TESTAMENT: "last_will_and_testament",
};
export const REACT_KEY = {
  GOOGLE_MAPS_API_KEY: "AIzaSyBrdj3xi32qprXJ3S8I8FNrwQKw8P2A8ww",
  // SENDGRID_API_KEY:
  //   "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",
  // SENDGRID_TEMPLATE_ID: "d-2979c7c06dc341d58ceadb1e784514a6",
};
export const INSURANCET_TYPES = {
  WHOLE_LIFE: 1,
  LONG_TERM: 3,
  UMBRELLA: 6,
  TERM_LIFE: 2,
  INDIVIDUAL_DISABILITY: 4,
  AUTO: 5,
};
export const INCLUDE_IN_PLAN = [
  {
    id: true,
    description: "Yes",
  },
  {
    id: false,
    description: "No",
  },
];
export const MANUAL_ASSETS_TYPE = [
  {
    id: "income",
    description: "Income",
  },
  {
    id: "depository",
    description: "Bank",
  },
  {
    id: "investment",
    description: "Investment",
  },
  {
    id: "loan",
    description: "Loan",
  },
  {
    id: "credit",
    description: "Credit",
  },
];

export const INVESTMENT_TYPES = [
  "401k",
  "Pensions",
  "Employe stock plan",
  "Brokerage account",
  "Equity Partnerships",
];
export const LOANS_TYPES = [
  "Mortgage",
  "Bussiness Loan",
  "Card Loan",
  "Student Loan",
  "Home Equity Line Of Credit ",
  "Adjustable Rate Mortgage",
  "Other Loans",
];

export const RATE_OF_PAY_RATE = [
  {
    id: "HOURLY",
    name: "Hourly",
  },
  {
    id: "WEEKLY",
    name: "Weekly",
  },
  {
    id: "MONTHLY",
    name: "Monthly",
  },
];
export const NOTIFICATION_TYPE = {
  USER_REGISTER_NOTIFICATION_TO_ADMIN: 1,
  ADMIN_RAISE_SUPPORT_TICKET_NOTIFICATION: 4,
  EKYC_REQUEST: 7,
  ADMIN_NOTIFICATION_FOR_USER_PHYSICAL_CARD_ORDER: 11,
  ADMIN_NOTIFICATION_FOR_ESTATE_FORM_SUBMITTION: 13,
};

export const PRESS_RELEASE_TYPES = [
  {
    id: "blog",
    name: "Blog",
  },
  {
    id: "newsletter",
    name: "Newsletter",
  },
];

export const TICKET_MANAGMENT_STATUS = [
  {
    id: "pending",
    name: "Pending",
  },
  {
    id: "rejected",
    name: "Rejected",
  },
  {
    id: "resolved",
    name: "Resolved",
  },
];
export const TICKET_MANAGMENT_PRIORITY = [
  {
    id: "high",
    name: "High",
  },
  {
    id: "medium",
    name: "Medium",
  },
  {
    id: "low",
    name: "Low",
  },
];

export const CARDS_TYPES = {
  VIRTUAL: "VIRTUAL",
  PHYSICAL: "PHYSICAL",
};

export const APP_URL = {
  PROD_PLAY_STORE:
    "https://play.google.com/store/apps/details?id=com.silverbackmobile",
  PROD_APPLE_STORE:
    "https://apps.apple.com/us/app/silverback-banking/id6477388491",
  QA_PLAY_STORE:
    "https://play.google.com/apps/internaltest/4701605805594745511",
};
